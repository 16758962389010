import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import TargetCard from '../item_Coupon/TargetCard';
import ActionCard from '../item_Coupon/ActionCard';
import moment from 'moment';
import { cloneObject, convertMetaArray, convertMetaObject, parseConnection } from '../../shared';
import RemarkCard from '../item_Coupon/RemarkCard';
import { DISCOUNT_REMOVE, DISCOUNT_SUBMIT, GET_QUERY } from './query';
import { client } from '../../shared/apollo';
import ExcludedDiscountsCard from './ExcludedDiscountsCard';
import { getSubmitActions, getSubmitTriggers, parseTriggersAndActions } from '../item_Coupon/utils';
import ExcludedProductsCard from '../item_Coupon/ExcludedProductsCard';
import CopyButton from '../../components/CopyButton';
import { Grid, Typography } from '@material-ui/core';
import CardContentContainer from '../item_SettingProductOrder/CardContentContainer';
import ExcludedDiscountCodesAndCouponsCard from './ExcludedDiscountCodesAndCouponsCard';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: true,
      remove: true,
    },
    tabs: [
      {
        name: translate.offer_discount_details,
        cards: [
          {
            fields: [
              {
                md: 12,
                render: () => (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6">{translate.discounts || '折扣'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        {translate.discounts_description ||
                          '"折扣" 是在結帳時自動生效的價格減免。不同於 "優惠券"或需要字母數字組合的 "優惠碼"，折扣不需要代碼，無需進行額外步驟即可享受優惠。'}
                      </Typography>
                    </Grid>
                  </Grid>
                ),
              },
              {
                type: 'text',
                label: translate.promotion_name + ' ' + translate.zh,
                name: 'metadata.name_hk',
                required: true,
                maxLength: 50,
                md: 12,
              },
              {
                type: 'text',
                label: translate.promotion_name + ' ' + translate.en,
                name: 'metadata.name_en',
                required: true,
                maxLength: 50,
                md: 12,
              },
              {
                md: 12,
                render: ({ values, setFieldValue }) => (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <CardContentContainer
                        hasActive={true}
                        cardTitle={translate.eligible_membership_only || '只供會員使用'}
                        cardSubtitle={
                          translate.eligible_membership_only_description || '僅限會員使用：優惠券僅供忠實客戶'
                        }
                        value={values?.membersOnly}
                        onChange={() => setFieldValue('membersOnly', !values?.membersOnly)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CardContentContainer
                        hasActive={true}
                        cardTitle={translate.discount_discount_after_expiry || '結束後續折扣'}
                        cardSubtitle={
                          translate.coupon_discount_after_expiry_description ||
                          '此折扣將作為最後一個應用折扣，並防止應用任何其他次序較低的折扣（除非另有指定）'
                        }
                        value={values?.discardSubsequent}
                        onChange={() => setFieldValue('discardSubsequent', !values?.discardSubsequent)}
                      />
                    </Grid>
                  </Grid>
                ),
              },
              {
                type: 'number',
                label: translate.coupon_order,
                name: 'sortIndex',
                required: true,
                inputProps: {
                  step: 1,
                  min: 0,
                },
                description: translate.coupon_order_description,
              },
              {
                type: 'datetime-local',
                label: translate.valid_start_date_time,
                name: 'publishAt',
                md: 12,
              },
              {
                type: 'datetime-local',
                label: translate.end_date_time,
                name: 'publishThru',
                md: 12,
              },
            ],
          },
        ],
      },
      {
        name: translate.offer_triggers_and_actions,
        cards: [
          TargetCard,
          ActionCard,
          RemarkCard,
          {
            fields: [
              {
                type: 'number',
                label: translate.discount_repeat_times,
                md: 6,
                inputProps: {
                  min: 1,
                  step: 1,
                },
                name: 'repeat',
                display: ({ loading, values }) =>
                  !loading &&
                  !!(values.triggers || []).find(
                    (trigger) => !!~['EVERY_AMOUNT', 'EVERY_QUANTITY'].indexOf(trigger.triggerValueType),
                  ),
              },
            ],
          },
          ExcludedDiscountsCard,
          ExcludedDiscountCodesAndCouponsCard,
          ExcludedProductsCard,
        ],
      },
    ],
  };

  renderExtraButtons({ loading, values }) {
    const { id } = this.state;
    if (!id || loading) return null;
    return (
      <CopyButton
        getCopyValues={() => {
          const clone = cloneObject(values);
          return {
            ...clone,
            id: undefined,
            handle: undefined,
          };
        }}
        disabled={loading}
        url={'/discounts/new'}
      />
    );
  }

  remove = async ({ values }) => {
    await client.mutate({
      mutation: DISCOUNT_REMOVE,
      variables: { id: values.id },
    });
  };

  submit = async (values) => {
    await client.mutate({
      mutation: DISCOUNT_SUBMIT,
      variables: {
        id: this.state.id,
        input: {
          companyId: await localStorage.getItem('companyId'),
          name: values.metadata.name_hk,
          description: '',
          remark: values.remark,
          sortIndex: values.sortIndex,
          discardSubsequent: values.discardSubsequent,
          triggers: getSubmitTriggers(values.triggers),
          actions: getSubmitActions(values.actions),
          publishAt: values.publishAt,
          publishThru: values.publishThru,
          membersOnly: values.membersOnly,
          active: values.active,
          repeat: values.repeat,
          excludedDiscountIds: values.excludedDiscounts.map((discount) => discount.id),
          excludedCouponIds: values.excludedCoupons.map((coupon) => coupon.id),
          excludedProductIds: values.excludedProductIds,
          metadata: convertMetaArray(values.metadata),
        },
      },
    });
    return true;
  };

  getInitialData({ node } = {}) {
    const now = moment().toISOString(),
      nextMonth = moment().add(1, 'month').toISOString();
    const { copyValues } = this.state || {};

    const {
      id,
      updatedAt,
      name,
      description,
      sortIndex = 0,
      publishAt = now,
      publishThru = nextMonth,
      active = true,
      repeat = 1,
      membersOnly = true,
      discardSubsequent = false,
      excludedDiscounts,
      excludedCoupons,
      excludedProductIds,
      metadata,
    } = node || {};

    return {
      id,
      updatedAt,
      name: name || convertMetaObject(metadata).name_hk,
      description,
      sortIndex,
      publishAt,
      publishThru,
      active,
      repeat,
      membersOnly,
      discardSubsequent,
      metadata: {
        name_hk: name,
        name_en: name,
        ...convertMetaObject(metadata),
      },
      excludedDiscounts: cloneObject(parseConnection(excludedDiscounts).nodes),
      excludedCoupons: cloneObject(parseConnection(excludedCoupons).nodes),
      excludedProductIds: excludedProductIds || [],
      ...parseTriggersAndActions(node),
      ...copyValues,
    };
  }
}
