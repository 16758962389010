import React from 'react';
import moment from 'moment';
import Activation from '../Activation';
import { extractTextFromHTML, NumberFormat, PriceFormat } from 'shared';
import { makeStyles, Avatar } from '@material-ui/core';
import Media from 'components/Media';
import ConnectionTableCell from './ConnectionTableCell';
import { Link } from 'react-router-dom';
import EllipsisTypography from '../EllipsisTypography';

const useStyles = makeStyles((theme) => ({
  cell: ({ width, align = 'left', hasSorter }) => ({
    textAlign: align,
    border: 'none',
    width,
    maxWidth: width,
    paddingRight: hasSorter && align === 'right' ? 26 : undefined,
  }),
}));

export default ({ item, field: _field, width, link, tableCellProps: _tableCellProps, onClick }) => {
  const field = parseFunctionValue(_field),
    {
      headerClassName = '',
      className = '',
      type,
      render,
      dataKey,
      fieldName,
      align = 'left',
      sortBy,
      value: _value,
      noLink,
      divider,
    } = field;
  const classes = useStyles({
    width,
    hasSorter: !!sortBy,
    align:
      align ||
      {
        bool: 'center',
        datetime: 'right',
      }[type],
  });
  const tableCellProps = {
    ..._tableCellProps,
    onClick: onClick ? (e) => onClick(e, item) : undefined,
    className: `${classes.cell} ${headerClassName} ${className}`,
    link: noLink ? undefined : link,
    width,
    divider,
  };

  const key = dataKey || fieldName,
    value = item[key] || (_value === 0 ? '0' : _value) || '';
  if (render) {
    try {
      return <ConnectionTableCell {...tableCellProps}>{render}</ConnectionTableCell>;
    } catch (e) {}
  }

  switch (type) {
    case 'link':
      const { to } = field || {};
      return (
        <ConnectionTableCell {...tableCellProps}>
          {!!to ? (
            <Link to={to}>
              <EllipsisTypography>{value}</EllipsisTypography>
            </Link>
          ) : (
            <EllipsisTypography>{value}</EllipsisTypography>
          )}
        </ConnectionTableCell>
      );
    case 'option':
    case 'options':
      const { options } = field || {};
      return (
        <ConnectionTableCell {...tableCellProps}>
          <EllipsisTypography>{(options?.find((opt) => opt.value === value) || {}).label || ''}</EllipsisTypography>
        </ConnectionTableCell>
      );
    case 'number':
      return (
        <ConnectionTableCell {...tableCellProps}>
          <EllipsisTypography color={value > 0 ? undefined : value < 0 ? 'error' : 'textSecondary'}>
            {NumberFormat(value)}
          </EllipsisTypography>
        </ConnectionTableCell>
      );
    case 'price':
      return (
        <ConnectionTableCell {...tableCellProps}>
          <EllipsisTypography variant={'caption'} color={value > 0 ? undefined : value < 0 ? 'error' : 'textSecondary'}>
            {PriceFormat(value)}
          </EllipsisTypography>
        </ConnectionTableCell>
      );
    case 'bool':
      return (
        <ConnectionTableCell {...tableCellProps}>
          <Activation active={value} />
        </ConnectionTableCell>
      );
    case 'datetime':
    case 'date':
      const time = moment(value);
      return (
        <ConnectionTableCell {...tableCellProps}>
          {time.isValid() ? (
            <EllipsisTypography variant={'body1'}>{time.format('D MMM YYYY LT')}</EllipsisTypography>
          ) : (
            <EllipsisTypography variant={'body1'}>{'-'}</EllipsisTypography>
          )}
        </ConnectionTableCell>
      );
    case 'medias':
    case 'media':
      let media = value;
      if (Array.isArray(value)) media = value[0];
      return (
        <ConnectionTableCell {...tableCellProps}>
          <Avatar variant={'rounded'}>
            <Media
              resizeMode={field?.resizeMode ?? 'cover'}
              // some image just return src didnt return a object
              src={(media || {}).optimizedSrc || (media || {}).src || media}
              style={{
                width: '100%',
                height: '100%',
              }}
              disabled={true}
            />
          </Avatar>
        </ConnectionTableCell>
      );

    case 'images':
    case 'image':
    case 's3':
      let image = value;
      if (Array.isArray(value)) image = value[0];
      return (
        <ConnectionTableCell {...tableCellProps}>
          <Avatar variant={'rounded'}>
            <Media
              resizeMode={'cover'}
              src={image}
              style={{
                width: '100%',
                height: '100%',
              }}
              disabled={true}
            />
          </Avatar>
        </ConnectionTableCell>
      );
    case 'html':
      return (
        <ConnectionTableCell {...tableCellProps}>
          <EllipsisTypography>{extractTextFromHTML(value)}</EllipsisTypography>
        </ConnectionTableCell>
      );
    default:
      return (
        <ConnectionTableCell {...tableCellProps}>
          <EllipsisTypography>{value}</EllipsisTypography>
        </ConnectionTableCell>
      );
  }

  function parseFunctionValue(props) {
    const obj = {};
    Object.keys(props).forEach((key) => {
      obj[key] = getValue(props[key]);
    });
    return obj;
  }
  function getValue(input) {
    if (typeof input === 'function') {
      try {
        return input(item);
      } catch (e) {
        console.error(e);
        return undefined;
      }
    }
    return input;
  }
};
