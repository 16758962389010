import React from 'react';
import { translate } from '../../../src/shared/translate';
import TargetCard from '../../../src/routes/item_Coupon/TargetCard';
import ActionCard from '../../../src/routes/item_Coupon/ActionCard';
import RemarkCard from '../../../src/routes/item_Coupon/RemarkCard';
import SubCodeCard from '../../../src/routes/item_Coupon/SubCodeCard';
import OrdersCard from '../../../src/routes/item_Coupon/OrdersCard';
import ExcludedProductsCard from '../../../src/routes/item_Coupon/ExcludedProductsCard';
import CheckboxField from '../../../src/components/FormPageMaker/Fields/CheckboxField';
import ExcludedDiscountsCard from '../../../src/routes/item_Discount/ExcludedDiscountsCard';
import CardContentContainer from '../item_SettingProductOrder/CardContentContainer';
import { Grid, Typography } from '@material-ui/core';
import CouponDescription from './CouponDescription';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import toast from '../../shared/toast';
import UserCouponCard from './UserCouponCard';
import ExcludedDiscountCodesAndCouponsCard from '../item_Discount/ExcludedDiscountCodesAndCouponsCard';

export function itemCouponCodeTabs() {
  return [
    {
      name: ({ values }) => (values.useSubCoupon ? translate.coupon_code_setting : translate.offer_details),
      cards: [
        {
          fields: [
            {
              md: 12,
              render: ({ values }) => (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {values.useSubCoupon
                        ? translate.coupons_and_discount_code || '優惠券'
                        : translate.discount_code || '優惠碼'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {values.useSubCoupon
                        ? translate.coupons_description ||
                          '"優惠券"是在結帳時使用的特定折扣或優惠。不同於"優惠碼"，優惠券一般會在結帳時由用戶自行選擇是否應用，而優惠碼則需要手動輸入特定的字母和數字組合。'
                        : translate.discount_code_description ||
                          '"優惠碼"是一個特殊的字母數字組合，顧客在結帳時輸入以獲得折扣或優惠。不同於"優惠券"，優惠碼需要手動輸入。用戶需主動輸入提供的優惠碼以享受獨家優惠。'}
                    </Typography>
                  </Grid>
                </Grid>
              ),
            },
            {
              type: 'text',
              label: ({ values }) =>
                values.useSubCoupon
                  ? translate.coupons_and_discount_code + ' ' + translate.name + ' ' + translate.zh
                  : translate.discount_code + ' ' + translate.name + ' ' + translate.zh,
              name: 'metadata.name_hk',
              required: true,
              maxLength: 50,
              md: 12,
            },
            {
              type: 'text',
              label: ({ values }) =>
                values.useSubCoupon
                  ? translate.coupons_and_discount_code + ' ' + translate.name + ' ' + translate.en
                  : translate.discount_code + ' ' + translate.name + ' ' + translate.en,
              name: 'metadata.name_en',
              required: true,
              maxLength: 50,
              md: 12,
            },
            {
              type: 'text',
              label: ({ values }) =>
                values.useSubCoupon ? translate.coupon_identifier || '優惠編號' : translate.offer_code || '優惠碼',
              name: 'handle',
              required: ({ values }) => (values.useSubCoupon ? false : true),
              maxLength: 50,
              description: ({ values }) =>
                values.useSubCoupon
                  ? translate.coupon_identifier_description ||
                    '當顧客兌換優惠券時，優惠編號將用作優惠券前綴，以便追蹤使用情況。如留空，系統將隨機產生一組編碼。'
                  : translate.only_allow_char_and_number || '只能輸入字母或數字（A-Z, 0-9）',
              render: ({ values, setFieldValue, isSubmitting }) => {
                return (
                  <InputField
                    disabled={isSubmitting}
                    required={values.useSubCoupon ? false : true}
                    type={'text'}
                    value={values?.handle}
                    onChange={(e) => {
                      if (!!e.target.value && !/^[A-Za-z0-9]+$/.test(e.target.value))
                        toast.error(translate.only_allow_char_and_number || '只能輸入字母或數字（A-Z, 0-9）');
                      setFieldValue('handle', e.target.value);
                    }}
                    maxLength={50}
                  />
                );
              },
            },
            {
              md: 12,
              display: ({ values }) => values.useSubCoupon,
              render: ({ values, disabled, setFieldValue }) => (
                <CouponDescription
                  descriptionHK={values?.metadata?.description_hk}
                  descriptionENG={values?.metadata?.description_en}
                  disabled={disabled}
                  onChangeHK={(v) => setFieldValue('metadata.description_hk', v)}
                  onChangeENG={(v) => setFieldValue('metadata.description_en', v)}
                />
              ),
            },
            {
              type: 'media',
              label: translate.image,
              name: 'media',
            },
            {
              md: 12,
              render: ({ values, setFieldValue }) => (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <CardContentContainer
                      hasActive={true}
                      cardTitle={translate.eligible_membership_only || '只供會員使用'}
                      cardSubtitle={
                        translate.eligible_membership_only_description || '僅限會員使用：優惠券僅供忠實客戶'
                      }
                      value={values?.membersOnly}
                      onChange={() => setFieldValue('membersOnly', !values?.membersOnly)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CardContentContainer
                      hasActive={true}
                      cardTitle={translate.discount_discount_after_expiry || '結束後續折扣'}
                      cardSubtitle={
                        translate.coupon_discount_after_expiry_description ||
                        '此折扣將作為最後一個應用折扣，並防止應用任何其他次序較低的折扣（除非另有指定）'
                      }
                      value={values?.discardSubsequent}
                      onChange={() => setFieldValue('discardSubsequent', !values?.discardSubsequent)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CardContentContainer
                      hasActive={true}
                      cardTitle={translate.available_for_usage || '可供使用'}
                      cardSubtitle={translate.available_for_usage_description || '確保優惠券已準備好供客戶使用'}
                      value={values?.enabled}
                      onChange={() => setFieldValue('enabled', !values?.enabled)}
                    />
                  </Grid>
                </Grid>
              ),
            },
            {
              type: 'number',
              label: translate.coupon_order,
              name: 'sortIndex',
              required: true,
              inputProps: {
                step: 1,
                min: 0,
              },
              description: translate.coupon_order_description,
            },
            {
              md: 12,
              display: ({ values }) => !values.useSubCoupon,
              label: translate.use_limit,
              type: 'number',
              name: 'publishLimit',
              disabled: ({ values }) => values.publishLimit === null || !!values.id,
              placeholder: ({ values }) => (values.publishLimit === null ? translate.infinite : ''),
              inputProps: ({ values }) =>
                values.publishLimit === null
                  ? undefined
                  : {
                      step: 1,
                      min: 1,
                    },
              InputProps: ({ values, setFieldValue, isSubmitting }) => ({
                endAdornment: !values.id && (
                  <CheckboxField
                    disabled={isSubmitting}
                    checked={values.publishLimit === null}
                    onChange={() => setFieldValue('publishLimit', values.publishLimit === null ? 1 : null)}
                    text={translate.infinite}
                    textVariant={'body2'}
                  />
                ),
              }),
            },
            {
              md: 12,
              display: ({ loading, values }) => !values.useSubCoupon,
              label: translate.member_use_limit,
              type: 'number',
              name: 'memberLimit',
              disabled: ({ values }) => values.memberLimit === null || !!values.id,
              placeholder: ({ values }) => (values.memberLimit === null ? translate.infinite : ''),
              inputProps: ({ values }) =>
                values.memberLimit === null
                  ? undefined
                  : {
                      step: 1,
                      min: 1,
                    },
              InputProps: ({ values, setFieldValue, isSubmitting }) => ({
                endAdornment: !values.id && (
                  <CheckboxField
                    disabled={isSubmitting}
                    checked={values.memberLimit === null}
                    onChange={() => setFieldValue('memberLimit', values.memberLimit === null ? 1 : null)}
                    text={translate.infinite}
                    textVariant={'body2'}
                  />
                ),
              }),
            },
            {
              type: 'datetime-local',
              label: translate.valid_start_date_time,
              name: 'validAt',
              display: ({ values }) => !values.useSubCoupon,
              md: 12,
            },
            {
              type: 'datetime-local',
              label: translate.valid_end_date_time,
              name: 'validThru',
              display: ({ values }) => !values.useSubCoupon,
              hasPermanent: true,
              md: 12,
            },
          ],
        },
        SubCodeCard,
      ],
    },
    {
      name: translate.offer_triggers_and_actions,
      cards: [
        TargetCard,
        ActionCard,
        RemarkCard,
        {
          fields: [
            {
              type: 'number',
              label: translate.discount_repeat_times,
              md: 6,
              inputProps: {
                min: 1,
                step: 1,
              },
              name: 'repeat',
              display: ({ loading, values }) =>
                !loading &&
                !!(values.triggers || []).find(
                  (trigger) => !!~['EVERY_AMOUNT', 'EVERY_QUANTITY'].indexOf(trigger.triggerValueType),
                ),
            },
          ],
        },
        ExcludedDiscountsCard,
        ExcludedDiscountCodesAndCouponsCard,
        ExcludedProductsCard,
      ],
    },
    {
      name: translate.related_orders,
      noPadding: true,
      cards: [OrdersCard],
      display: () => {
        const { location } = this.props;
        if (this.type === translate.discount_code)
          return new RegExp(`^/discount_code/[0-9a-f-]{36}`).test((location || {}).pathname);
        if (this.type === translate.coupons)
          return new RegExp(`^/coupons/[0-9a-f-]{36}`).test((location || {}).pathname);
      },
    },
    {
      name: translate.sub_coupon_records || '子優惠券記錄',
      noPadding: true,
      cards: [UserCouponCard],
      display: () => {
        const { location } = this.props;
        if (this.type === translate.coupons)
          return new RegExp(`^/coupons/[0-9a-f-]{36}`).test((location || {}).pathname);
        return false;
      },
    },
  ];
}
