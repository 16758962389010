import React from 'react';
import { translate } from '../../shared/translate';
import PlatformsField from './PlatformsField';
import { Grid } from '@material-ui/core';
import StyledConfigList, { AddButton } from '../../components/StyledConfigList';

export const platforms = [
  { key: 'facebook', label: 'Facebook' },
  { key: 'youtube', label: 'Youtube' },
  { key: 'instagram', label: 'Instagram' },
  { key: 'whatsapp', label: 'Whatsapp' },
  { key: 'wechat', label: 'WeChat ID' },
  { key: 'email', label: translate.contact_email },
  { key: 'tel', label: translate.contact_phone_no },
  // { key: 'cs', label: 'CS' },
];

const PlatformsCard = (props) => {
  const { values, setFieldValue, disabled } = props;
  const { platforms } = values;

  return (
    <StyledConfigList>
      {platforms.map((v, i) => {
        return (
          <Grid item key={i} xs={12}>
            <PlatformsField
              availablePlatformKeys={platforms?.map((v) => v?.key)}
              value={v}
              onChange={(v) => {
                if (v) {
                  setFieldValue(
                    'platforms',
                    platforms.map((_v, j) => (i === j ? v : _v)),
                  );
                } else {
                  setFieldValue(
                    'platforms',
                    platforms.filter((__, j) => j !== i),
                  );
                }
              }}
              disabled={disabled}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <AddButton
          title={translate.add}
          disabled={disabled}
          onClick={() => {
            setFieldValue('platforms', platforms.concat({ key: '', value: '' }));
          }}
        />
      </Grid>
    </StyledConfigList>
  );
};

export default PlatformsCard;
