import React from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import { translate } from '../../shared/translate';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import SelectField from '../../components/FormPageMaker/Fields/SelectField';
import { platforms } from './PlatformsCard';
import TrashButton from '../../components/TrashButton';

const PlatformsField = ({ disabled, onChange, value = {}, availablePlatformKeys = [] }) => {
  const filteredPlatformList = platforms.filter((item) => {
    if (availablePlatformKeys.includes(item?.key)) {
      if (value?.key === item?.key) return true;
      return false;
    } else return true;
  });

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {/* <Grid
          item
          md={2}
          style={{
            margin: '0.5rem',
          }}
        >
          <SelectField
            options={filteredPlatformList.map((platform) => ({
              label: platform?.label,
              value: platform?.key,
            }))}
            disabled={disabled}
            value={value?.key}
            onChange={(e) => {
              onChange({
                ...value,
                key: e?.target?.value,
              });
            }}
          />
        </Grid> */}
      <Grid item xs={true}>
        <InputField
          style={{
            marginLeft: '0.5rem',
          }}
          disabled={disabled}
          value={value?.value}
          type={'text'}
          placeholder={translate.link_or_text}
          onChange={(e) => {
            onChange({
              ...value,
              value: e?.target?.value,
            });
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position={'start'}
                style={{
                  width: '20%',
                }}
              >
                <SelectField
                  options={filteredPlatformList.map((platform) => ({
                    label: platform?.label,
                    value: platform?.key,
                  }))}
                  disabled={disabled}
                  value={value?.key}
                  onChange={(e) => {
                    onChange({
                      ...value,
                      key: e?.target?.value,
                    });
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <TrashButton
          onClick={() => {
            onChange(undefined);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PlatformsField;
