import React from 'react';
import ConfigCard from '../ConfigCard';
import { LV } from '../../../components/ReceiptTemplatePreviewer';
import PreviewCard from '../PreviewCard';
import SplitCard from '../../item_EmailTemplate/SplitCard';
import { defaultSuggestions } from '../defaultSuggestion';
import LayoutFront from '../LayoutFront';
import { PriceFormat } from '../../../shared';

export default class LayoutReceipt extends LayoutFront {
  type = 'RECEIPT';

  state = {
    ...this.state,
    cards: [
      (props) => (
        <SplitCard
          configCard={
            <ConfigCard
              suggestions={[
                { label: '#index', value: '{{index}}' },
                { label: '#count', value: '{{count}}' },
                { label: '#subtitle', value: '{{subtitle}}' },
                { label: '#description', value: '{{description}}' },
                ...defaultSuggestions,
              ]}
              allows={['isTitle', 'isText', 'isLV', 'isDivider', 'isLogo', 'isQRCode', 'isCut']}
              {...props}
            />
          }
          previewCard={
            <PreviewCard
              standardWidth={300}
              extraData={{}}
              extraRenderer={{
                isItems: ({ items }, config) => {
                  const size = config?.size;
                  return items.map((item, i) => (
                    <div key={i}>
                      <LV label={item?.description} size={size} value={PriceFormat(item?.unitPrice * item?.quantity)} />
                      <div style={{ fontSize: { 1: '1em', 2: '1.5rem', 3: '2rem' }[size] || '1em' }}>
                        {item?.remark}
                      </div>
                    </div>
                  ));
                },
              }}
              {...props}
            />
          }
        />
      ),
    ],
  };
}
