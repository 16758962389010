import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import moment from 'moment';
import {
  cloneObject,
  convertMetaArray,
  convertMetaObject,
  getRandomString,
  parseConnection,
  toInputMedia,
} from '../../shared';
import { COUPON_REMOVE, COUPON_SUBMIT, GET_QUERY } from './query';
import { client } from '../../shared/apollo';
import { getSubmitActions, getSubmitTriggers, parseTriggersAndActions } from './utils';
import CopyButton from '../../components/CopyButton';
import { itemCouponCodeTabs } from './itemCouponCodeTabs';
import { translate } from '../../shared/translate';

export default class extends FormPageMaker {
  type = translate.coupons;
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: true,
      remove: true,
    },
    tabs: itemCouponCodeTabs.bind(this)(),
  };

  remove = async ({ values }) => {
    await client.mutate({
      mutation: COUPON_REMOVE,
      variables: { id: values.id },
    });
  };

  renderExtraButtons({ loading, values }) {
    const { id } = this.state;
    if (!id || loading) return null;
    return (
      <CopyButton
        getCopyValues={() => {
          const clone = cloneObject(values);
          return {
            ...clone,
            id: undefined,
            handle: undefined,
          };
        }}
        disabled={loading}
        url={'/coupons/new'}
      />
    );
  }

  submit = async (values) => {
    if (!!values?.handle && !/^[A-Za-z0-9]+$/.test(values?.handle))
      throw new Error(
        `${translate.coupon_identifier || '優惠編號'}：${
          translate.only_allow_char_and_number || '只能輸入字母或數字（A-Z, 0-9）'
        }`,
      );

    await client.mutate({
      mutation: COUPON_SUBMIT,
      variables: {
        id: this.state.id,
        input: {
          companyId: localStorage.getItem('companyId'),
          enabled: !!values.enabled,
          handle: !!values.handle
            ? values.handle
            : getRandomString(5, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ') + moment().format('YYYYMMDDHHmmss'),
          media: toInputMedia(values.media),
          name: values.metadata.name_hk,
          description: '',
          repeat: values.repeat,
          remark: values.remark,
          publishAt: values.publishAt,
          publishThru: values.publishThru,
          publishLimit: values.publishLimit,
          memberLimit: values.memberLimit,
          validAt: values.publishAt || values.validAt,
          validThru: values.publishThru || values.validThru,
          validForPeriod: values.subCouponValidMode === 'PERIOD' ? values.validForPeriod : null,
          active: values.active,
          resolveCode: values.isResolvable ? localStorage.getItem('companyId').replace(/^.*-(.*?)$/g, '$1') : null,
          membersOnly: values.membersOnly,
          discardSubsequent: values.discardSubsequent,
          triggers: getSubmitTriggers(values.triggers),
          actions: getSubmitActions(values.actions),
          excludedDiscountIds: values.excludedDiscounts.map((discount) => discount.id),
          excludedCouponIds: values.excludedCoupons.map((coupon) => coupon.id),
          excludedProductIds: values.excludedProductIds,
          sortIndex: values.sortIndex,
          metadata: convertMetaArray(values.metadata),
        },
        subCouponInput: {
          applyCode: 'SUB_CODE',
          memberPointCost: values.memberPointCost,
          numberOfRedeem: -1,
          isRedeemable: true,
        },
      },
    });
    return true;
  };

  getInitialData({ node } = {}) {
    const { copyValues } = this.state || {};
    const now = moment().toISOString(),
      nextMonth = moment().add(1, 'month').toISOString();
    const {
      id,
      handle,
      updatedAt,
      name,
      description,
      sortIndex = 0,
      media,
      validAt = now,
      validThru = nextMonth,
      validForPeriod = 'P1M',
      publishLimit = 100,
      memberPointCost = 100,
      publishAt = now,
      publishThru = nextMonth,
      active = true,
      resolveCode,
      membersOnly = true,
      discardSubsequent = false,
      enabled = true,
      excludedDiscounts,
      excludedCoupons,
      excludedProductIds,
      userCoupons,
      memberLimit = 1,
      metadata,
      repeat = 1,
    } = node || {};

    return {
      id,
      enabled,
      handle,
      updatedAt,
      name: name || convertMetaObject(metadata).name_hk,
      description,
      isResolvable: !!resolveCode,
      media,
      validAt,
      validThru,
      validForPeriod,
      subCouponValidMode: !!validForPeriod ? 'PERIOD' : 'RANGE',
      publishLimit,
      publishAt,
      publishThru,
      active,
      membersOnly,
      discardSubsequent,
      useSubCoupon: true,
      memberPointCost,
      excludedDiscounts: cloneObject(parseConnection(excludedDiscounts).nodes),
      excludedCoupons: cloneObject(parseConnection(excludedCoupons).nodes),
      excludedProductIds: excludedProductIds || [],
      userCoupons: cloneObject(parseConnection(userCoupons).nodes),
      sortIndex,
      memberLimit,
      repeat,
      metadata: {
        name_hk: name,
        name_en: name,
        ...convertMetaObject(metadata),
      },
      ...parseTriggersAndActions(node),
      ...copyValues,
    };
  }
}
