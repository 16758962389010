import { translate } from '../../../../shared/translate';
import confirmation from '../../../../shared/confirmation';
import { actionWrapper } from '../../../../shared';
import { client } from '../../../../shared/apollo';
import { DELIVERY_NOTES_COMPLETE } from './query';

const MenuComplete = {
  name: `${translate.setting} ${translate.completed}`,
  enable: function () {
    return this.getSelectionCount() > 0;
  },
  onClick: async function () {
    if (await confirmation(translate.formatString(translate['confirm_complete_n_orders'], this.getSelectionCount()))) {
      await actionWrapper(
        async () => {
          await client.mutate({
            mutation: DELIVERY_NOTES_COMPLETE,
            variables: {
              shopId: localStorage.getItem('shopId'),
              filter: this.getSelectionFilter(),
            },
          });
        },
        {
          messages: {
            success: translate.update_success,
          },
        },
      );
    }
  },
};

export default MenuComplete;
