import React, { useRef, useState } from 'react';
import { Grid, InputBase } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '../../IconRender';
import { ReactComponent as IconSend } from '../../../assets/icon/send.svg';
import { ReactComponent as IconFileClip } from '../../../assets/icon/file-clip.svg';
import { ReactComponent as IconMessage } from '../../../assets/icon/message.svg';
import { translate } from '../../../shared/translate';
import EmojiButton from './EmojiButton';

const InputToolbar = ({ disabled, onMessageSubmit = (_) => _, onFileSubmit = (_) => _ }) => {
  const [value, setValue] = useState('');
  const uploader = useRef(undefined);
  return (
    <Grid
      container
      alignItems={'center'}
      style={{ backgroundColor: '#F9F9F9', padding: '0px 15px', margin: '5px 0', borderRadius: '10px' }}
    >
      <Grid item>
        <IconButton disabled={true} edge={'start'}>
          <Icon type={'svg'} icon={IconMessage} viewBox={'0 0 26 20'} />
        </IconButton>
      </Grid>
      <Grid item>
        <EmojiButton
          disabled={disabled}
          onEmoji={(e) => {
            setValue(`${value || ''}${e?.emoji ?? ''}`);
          }}
        />
      </Grid>
      <Grid item xs style={{ width: '100%', position: 'relative' }}>
        <InputBase
          id="outlined-adornment-password"
          fullWidth
          type={'text'}
          value={value}
          disabled={disabled}
          placeholder={translate.input_content}
          onClick={() => fixFocusing()}
          onKeyDown={(e) => {
            fixFocusing();
            if (e.keyCode === 13) {
              setValue('');
              onMessageSubmit(value);
            }
          }}
          onChange={(e) => {
            setValue(e.target.value);
            fixFocusing();
          }}
        />
      </Grid>
      <Grid item>
        <label style={{ margin: 0 }}>
          <input
            type={'file'}
            ref={uploader}
            accept="*"
            disabled={disabled}
            style={{
              display: 'none',
            }}
            multiple={false}
            onChange={(e) => {
              const files = e.target.files;
              const file = files[0];
              !!file && onFileSubmit(file);
            }}
          />
          <IconButton
            onClick={() => {
              uploader.current.click();
            }}
            disabled={disabled}
          >
            <Icon type={'svg'} icon={IconFileClip} viewBox={'0 0 22 24'} />
          </IconButton>
        </label>
      </Grid>
      <Grid item>
        <IconButton
          edge={'end'}
          onClick={() => {
            setValue('');
            onMessageSubmit(value);
          }}
          color={'secondary'}
          disabled={disabled}
        >
          <Icon type={'svg'} icon={IconSend} viewBox={'0 0 25 24'} />
        </IconButton>
      </Grid>
    </Grid>
  );

  function fixFocusing() {
    const input = document.getElementById('outlined-adornment-password');
    input.focus();
  }
};

export default InputToolbar;
