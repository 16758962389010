import { gql } from '@apollo/client';

export const FRAGMENT_ORDERDELIVERYNOTE = gql`
  fragment OrderDeliveryNote on OrderDeliveryNote {
    status
    address {
      person
      tel
      email
      lines
      city
      country
    }
    shippingProvider {
      id
      name
      provider
    }
    deliveryDetails
    trackingNumber
    order {
      id
      referenceNo
      total
    }
    items {
      id
      quantity
      orderItem {
        id
        sku
        serviceBundle {
          serviceBundle {
            id
            name
          }
        }
        bundleProduct {
          bundleProduct {
            id
            name
            sku
          }
          options {
            id
            label
            price
            variation {
              id
              sku
              combination {
                option
                name
              }
            }
            product {
              id
              sku
              name
            }
          }
        }
        addOnProduct {
          id
          sku
          name
        }
        productVariation {
          id
          sku
        }
        description
        remark
        unitPrice
      }
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      createdAt
      updatedAt
      ...OrderDeliveryNote
    }
  }
  ${FRAGMENT_ORDERDELIVERYNOTE}
`;

export const SUBMIT_QUERY = gql`
  mutation ($id: ID, $input: DeliveryNoteSetInput!) {
    deliveryNoteSet(id: $id, input: $input) {
      id
      createdAt
      updatedAt
      ...OrderDeliveryNote
    }
  }
  ${FRAGMENT_ORDERDELIVERYNOTE}
`;

export const COMPLETE_QUERY = gql`
  mutation ($id: ID!) {
    deliveryNoteComplete(id: $id) {
      id
      status
    }
  }
`;

export const PROCESS_QUERY = gql`
  mutation ($id: ID!) {
    deliveryNoteProcess(id: $id) {
      id
      status
      trackingNumber
    }
  }
`;
