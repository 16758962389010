import { useLocation } from 'react-router-dom';
import { isJWTValid } from '../../shared';
import { useInterval } from 'react-use';
import { useEffect } from 'react';

const { REACT_APP_MAIN_SITE_URL } = process.env;

export default () => {
  const { pathname } = useLocation();

  useInterval(() => {
    const token = localStorage.getItem('token');
    if (!isJWTValid(token)) {
      if (pathname !== '/login') {
        window.location.href =
          `${REACT_APP_MAIN_SITE_URL}/login?redirectUrl=${window.location.origin}/login` || '/login';
      }
    }
  }, 2000);

  useEffect(() => {
    localStorage.setItem('latestPathname', pathname);
  }, [pathname]);

  return null;
};
