import { Grid, List, ListItem, ThemeProvider, Typography, useTheme } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React from 'react';
import SystemButton from '../../../../components/SystemButton';
import useBreakPoint from '../../../../components/useBreakPoint';
import TableItem from './TableItem';
import { translate } from '../../../../shared/translate';

const TablesList = ({ venueName, value, onChange, parentHeight: _parentHeight }) => {
  const theme = useTheme();
  const breakpoint = useBreakPoint();
  const isWeb = !!~['xl', 'lg', 'md'].indexOf(breakpoint);
  const parentHeight = isWeb ? _parentHeight : 300;

  return (
    <ThemeProvider
      theme={{
        ...theme,
        overrides: {
          ...theme.overrides,
          MuiOutlinedInput: {
            notchedOutline: {
              borderColor: 'transparent',
              '&:hover': {
                borderColor: {
                  background: '#000000',
                  opacity: 0.2,
                },
              },
            },
          },
        },
      }}
    >
      <Grid
        container
        direction={'column'}
        justifyContent={'space-between'}
        style={{
          height: parentHeight,
          width: '100%',
          borderTopRightRadius: 9,
          borderBottomRightRadius: 9,
          backgroundColor: '#f5f4f4',
          padding: '0px 0px 16px 0px',
        }}
      >
        <Grid item style={{ maxHeight: parentHeight - 75, overflowY: 'scroll' }}>
          <List style={{ padding: 0 }}>
            <ListItem style={{ padding: '8px 16px' }}>
              <Typography variant={'h5'} style={{ color: '#665858', fontSize: 24, fontWeight: '700' }}>
                {venueName}
              </Typography>
            </ListItem>
            {value.map((item, i) => (
              <TableItem
                value={item}
                index={i}
                onChange={(e) => {
                  onChange(
                    value.map((_item, j) => {
                      if (i === j) return { ...e };
                      return { ..._item };
                    }),
                  );
                }}
              />
            ))}
            {/* {value.map((activeItem, i) => (
            // console.log('activeItem', activeItem, 'index', i),
            <TableItem
              value={activeItem}
              index={i}
              onChange={(e) => {
                onChange(
                  value.map((_item, j) => {
                    if (i === j) return { ...e };
                    return { ..._item };
                  }),
                );
              }}
            />
          ))} */}
          </List>
        </Grid>
        <Grid item style={{ padding: '0px 32px' }}>
          <SystemButton
            variant={'outlined'}
            color={'#ddd'}
            startIcon={<Add />}
            onClick={() => {
              onChange(
                value.concat({
                  name: `Table ${value.length + 1}`,
                  shapeData: [0, 0, 100, 100, 0],
                  metadata: {
                    active: 1,
                    ignoreTimeLimited: 1,
                    ignoreMinimumConsumption: 1,
                    ignoreMinimumConsumptionPer: 1,
                    isDelete: 0,
                  },
                }),
              );
            }}
            style={{ width: '100%', borderRadius: 12, color: 'rgba(0,0,0,0.7)' }}
          >
            {translate.add_new_tables || '新增餐桌'}
          </SystemButton>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default TablesList;
