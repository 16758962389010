import React, { Fragment, useEffect, useRef, useState } from 'react';
import { translate } from '../../shared/translate';
import {
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useDebounce } from 'react-use';
import { Icon } from '../IconRender';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import { fuzzyFilterOptions, getRandomString, JSONParseSafely } from '../../shared';
import useBreakPoint from '../useBreakPoint';

const SelectCombo = ({
  loading,
  placeholder = translate.please_select,
  onChange = (_) => _,
  options = [],
  required,
  label,
  onOpen = (_) => _,
  onScrollBottom = (_) => _,
  onSearchChange = (_) => _,
  value,
  multiple,
  repeatable,
  getOptionLabel = (opt) => opt?.label ?? '',
  InputProps,
  getOptionSelected,
  helperText,
  copyableType,
  disableCloseOnSelect,
  variant = 'outlined',
  ...props
}) => {
  const handler = useRef({ id: getRandomString() });
  const theme = useTheme();
  const [key, setKey] = useState(0);
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState('');
  const hasGroup = !!options.find((opt) => !!opt.group);

  const breakPoint = useBreakPoint();
  const isUnderLG = ['xs', 'sm', 'md'].includes(breakPoint);

  useEffect(() => {
    setKey(key + 1);
  }, [value]);

  useDebounce(
    () => {
      onSearchChange(input);
    },
    500,
    [input],
  );

  useEffect(() => {
    onOpen(open);
  }, [open]);

  const autoCompleteGetOptionLabel = (option) => {
    const a = getOptionLabel(option);
    if (a && typeof a === 'string') return a;
    if (option.label && typeof option.label === 'string') return option.label;
    if (option.id && typeof option.id === 'string') return option.id;
    if (option.value && typeof option.value === 'string') return option.value;
    return '';
  };

  return (
    <ClickAwayListener disableReactTree={isUnderLG ? false : true} onClickAway={() => setOpen(false)}>
      <span>
        <Autocomplete
          key={key}
          id={handler.current.id}
          fullWidth={true}
          open={open}
          filterOptions={fuzzyFilterOptions}
          value={value}
          groupBy={hasGroup ? (opt) => opt?.group : undefined}
          onOpen={() => setOpen(true)}
          onClose={(e, event) => {
            if (event === 'toggleInput') {
            } else setOpen(false);
          }}
          inputValue={input}
          onInputChange={(e, input) => setInput(input)}
          onBlur={() => {
            const matchOptions = fuzzyFilterOptions(options, {
              inputValue: input,
              getOptionLabel: autoCompleteGetOptionLabel,
            });
            if (matchOptions?.length === 1) {
              onChange(multiple ? [...value, matchOptions[0]] : matchOptions[0]);
            }
          }}
          ChipProps={{
            color: 'secondary',
          }}
          renderOption={(option) => {
            if (option?.visible === false) return null;

            const label = (
              <Typography style={{ color: option.active === false && theme.palette.error.light }}>
                {getOptionLabel(option) || option.id || option.value}
              </Typography>
            );

            if (option?.icon)
              return (
                <Grid container alignItems={'center'} spacing={1}>
                  <Grid item>{option?.icon}</Grid>
                  <Grid item>{label}</Grid>
                </Grid>
              );
            return label;
          }}
          ListboxProps={{
            onScroll: (e) => {
              const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
              const tolerance = 20;
              if (scrollHeight - scrollTop - clientHeight <= tolerance) onScrollBottom();
            },
          }}
          size={'small'}
          onChange={(e, v) => onChange(v || undefined)}
          disableCloseOnSelect={disableCloseOnSelect || multiple}
          getOptionDisabled={(option) => option.disabled}
          getOptionSelected={(a, b) => {
            if (repeatable && multiple) return false;
            if (getOptionSelected) return getOptionSelected(a, b);
            return a === b;
          }}
          getOptionLabel={autoCompleteGetOptionLabel}
          options={options}
          loadingText={translate.loading}
          noOptionsText={translate.no_data_lite}
          renderInput={(params) => (
            <TextField
              {...params}
              onFocus={() => setOpen(true)}
              label={label}
              helperText={helperText}
              placeholder={placeholder}
              required={multiple ? value.length === 0 && required : required}
              onPaste={(e) => {
                try {
                  const pastedText = e?.clipboardData?.getData('text/plain');
                  if (pastedText && copyableType) {
                    const [a1, companyId, a2] = pastedText.split('∆');
                    if (companyId !== localStorage.getItem('companyId')) {
                      e.preventDefault();
                      throw new Error(translate.invalid_copy_value);
                    }

                    if (a1 && a2 && a1 === copyableType) {
                      const data = JSONParseSafely(a2);
                      if (data) {
                        if (multiple && Array.isArray(data)) {
                          onChange(value.concat(data));
                          e.preventDefault();
                        } else if (!multiple) {
                          onChange(data);
                          e.preventDefault();
                        }
                      }
                    } else if (a1 && a2 && a1 !== copyableType) {
                      e.preventDefault();
                      throw new Error(translate.invalid_copy_value);
                    }
                  }
                } catch (e) {
                  toast.error(errorParser(e));
                }
              }}
              variant={variant}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  try {
                    e.preventDefault();
                    // e.target.blur();
                  } catch (e) {}
                }
              }}
              // eslint-disable-next-line
              InputProps={{
                ...params.InputProps,
                ...InputProps,
                style: { ...InputProps?.style },
                startAdornment: (
                  <>
                    {!!copyableType && (multiple ? value?.length > 0 : !!value) && (
                      <SelectComboCopyButton copyableType={copyableType} value={value} />
                    )}
                    {params?.InputProps?.startAdornment}
                    {InputProps?.startAdornment}
                    {value?.icon && <InputAdornment position={'start'}>{value?.icon}</InputAdornment>}
                  </>
                ),
                endAdornment: (
                  <Fragment>
                    {loading && (
                      <CircularProgress
                        size={15}
                        style={{
                          position: 'absolute',
                          zIndex: 1,
                          top: 2,
                          right: 2,
                          padding: 3,
                        }}
                      />
                    )}
                    {params?.InputProps?.endAdornment}
                    {InputProps?.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          loading={loading && (!options || options.length === 0)}
          multiple={multiple}
          disablePortal={false}
          {...props}
        />
      </span>
    </ClickAwayListener>
  );
};

export default SelectCombo;

const SelectComboCopyButton = ({ copyableType, value }) => {
  const [copied, setCopied] = useState(false);

  useDebounce(
    () => {
      if (copied) setCopied(false);
    },
    1000,
    [copied],
  );

  return (
    <IconButton
      size={'small'}
      onClick={() => {
        try {
          if (value) {
            const text = `${copyableType}∆${localStorage.getItem('companyId')}∆${JSON.stringify(value)}`;
            navigator.clipboard.writeText(text);
            setCopied(true);
          }
        } catch (e) {}
      }}
      color={copied ? 'primary' : undefined}
    >
      <Icon icon={copied ? 'faCheck' : 'faCopy'} style={{ fontSize: 12 }} />
    </IconButton>
  );
};
