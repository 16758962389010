import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import { useQuery } from '@apollo/client';
import { GET_CHANNEL_PLAYERS, GET_CUSTOMER } from './query';
import { useMeasure } from 'react-use';
import TabCustomerTags from './TabCustomerTags';
import CustomerInfo from './CustomerInfo';
import TabButtons from './TabButtons';
import Media from './Media';
import Links from './Links';
import { convertMetaObject, getTokenData, JSONParseSafely, parseConnection } from '../../../shared';
import ChannelCustomerDetailOrders from './Orders';
import OrderPreview from './OrderPreview';

const ChannelCustomerDetail = ({ channelId, onCustomerData = (_) => _, header }) => {
  const { loading: loadingChannel, data: { node: channel } = {} } = useQuery(GET_CHANNEL_PLAYERS, {
      skip: !channelId,
      variables: { id: channelId },
    }),
    channelType = channel?.type,
    player = parseChannelCustomer(channel) ?? {
      name: 'Guest',
      picture: '',
    },
    customerId = player?.id;

  const { loading: loadingCustomer, data: { node: customer } = {} } = useQuery(GET_CUSTOMER, {
    skip: !customerId,
    variables: { id: customerId },
  });

  const loading = loadingChannel || loadingCustomer;

  useEffect(() => {
    onCustomerData(
      !loadingChannel
        ? {
            ...player,
            ...customer,
          }
        : undefined,
    );
  }, [loadingChannel, loadingCustomer]);

  const [tab, setTab] = useState('tag');
  const [ref, { height }] = useMeasure();

  return (
    <Box height={'100%'} display={'flex'} flexDirection={'column'}>
      {header}
      <Box p={2}>
        <CustomerInfo loading={loading} customer={{ ...player, ...customer }} />
      </Box>
      {!!customerId || loading ? (
        <>
          <Box p={2} style={{ backgroundColor: '#F9F9F9' }}>
            <TabButtons tab={tab} disabled={loading} onChange={setTab} />
          </Box>
          <Box ref={ref} flex={1} position={'relative'} style={{ backgroundColor: '#F9F9F9' }}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height,
                overflowX: 'hidden',
              }}
            >
              {
                {
                  tag: <TabCustomerTags loading={loading} tags={customer?.hashtags} />,
                  order: <ChannelCustomerDetailOrders customerId={customerId} />,
                  file: <Media channelId={channelId} />,
                  link: <Links channelId={channelId} />,
                  info: <></>,
                }[tab]
              }
            </div>
          </Box>
        </>
      ) : (
        <></>
      )}
      {channelType === 'ORDER' && <OrderPreview orderId={channel?.code} />}
    </Box>
  );
};

export default ChannelCustomerDetail;

export function parseChannelCustomer(channel) {
  const shopId = localStorage.getItem('shopId');
  const { players = [] } = channel || {};
  return (players ?? []).find((player) => {
    if (player.name === 'Shop') return false;
    if (!!~player.id.indexOf(shopId)) return false;
    if (!!~player.email.indexOf(player.id)) return false;
    return true;
  });
}

export function parseChannelStaff(channel) {
  const shopId = localStorage.getItem('shopId');
  const me = getTokenData();
  const { players = [], metadata = [] } = channel || {};

  const staff =
    JSONParseSafely(convertMetaObject(metadata)?.designated, null) ||
    (players ?? []).find((player) => {
      if (player.name === me?.name) return true;
      else if (!!~(player?.id || '').indexOf(shopId)) return true;
      return false;
    });
  if (staff?.name === 'Shop') {
    if (staff?.email === me?.email) return { ...staff, name: me?.name };
  }
  return staff;
}

export function isStaffPlayer(player) {
  const shopId = localStorage.getItem('shopId');
  const me = getTokenData();

  if (player?.name === me?.name) return true;
  else if (player?.name === 'Shop') return true;
  else if (!!~(player?.id || '').indexOf(shopId)) return true;
  return false;
}

export function parseChannelPreviewContent(channel) {
  const { messages } = channel || {};

  const {
      nodes: [{ content, type, process } = {}],
    } = parseConnection(messages),
    { output } = process || {},
    { message } = JSONParseSafely(output, {});

  return (
    message ||
    {
      IMAGE: `[${translate.image}]`,
      VIDEO: `[${translate.video}]`,
      FILE: `[${translate.file}]`,
    }[type] ||
    content ||
    ''
  );
}
