import React from 'react';
import TinyComponent from 'components/TinyComponent';
import { Box } from '@material-ui/core';
import LSkeleton from '../../LSkeleton';

export default ({ loading, style, ...props }) => {
  if (loading)
    return (
      <div>
        <LSkeleton height={30} />
        <LSkeleton height={30} />
        <LSkeleton height={30} />
      </div>
    );
  return (
    <Box border={1} borderColor={'grey.300'} overflow={'hidden'} style={{ borderRadius: '10px 10px', ...style }}>
      <TinyComponent {...props} />
    </Box>
  );
};
