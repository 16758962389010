import React from 'react';
import { Card } from '@material-ui/core';
import SelectField from '../../../components/FormPageMaker/Fields/SelectField';
import _ from 'lodash';
import module from './module';

const ComponentItemField = ({
  suggestions = [],
  options: _options = module,
  allows = _.map(_options, 'value'),
  disableOptions = [],
  extraOptions = [],
  value: item,
  onChange,
  disabled,
}) => {
  const allOptions = _options.concat(extraOptions);
  const options = _options.filter((opt) => allows.includes(opt.value)).concat(extraOptions);
  const type = Object.keys(item ?? {}).find((key) => /^is[A-Z]/.test(key));

  const option = allOptions.find((opt) => opt?.value === type);
  const Module = option?.render;

  const optValue = option?.value;
  const allowEdit = !optValue || !!options.find((opt) => opt?.value === optValue);

  return (
    <Card variant={'outlined'} style={{ display: 'flex', borderRadius: 10, overflow: 'hidden' }}>
      <div style={{ marginTop: 5, marginLeft: 8 }}>
        <SelectField
          key={optValue}
          variant={'standard'}
          InputProps={{
            disableUnderline: true,
            style: {
              width: 120,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              border: 'none',
              color: '#5a5a5a',
            },
          }}
          value={optValue}
          onChange={(e) => {
            const _item = { ...item };
            Object.keys(_item).forEach((key) => {
              if (/^is[A-Z]/.test(key)) {
                delete _item[key];
              }
            });
            _item[e?.target?.value] = true;
            onChange(_item);
          }}
          options={allowEdit ? options : options.concat(option)}
          disabled={disabled || !allowEdit}
        />
      </div>
      {!!Module && !disableOptions?.includes(type) ? (
        <div style={{ flex: 1, borderLeft: '1px solid #ddd' }}>
          <Module suggestions={suggestions} value={item} onChange={onChange} disabled={disabled} />
        </div>
      ) : (
        <div
          style={{
            flex: 1,
            borderLeft: '1px solid #ddd',
            backgroundColor: '#d1d1d1',
            opacity: 0.5,
          }}
        />
      )}
    </Card>
  );
};

export default ComponentItemField;
