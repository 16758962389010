import React from 'react';
import { Box, Card, CardContent, Grid } from '@material-ui/core';
import StyledConfigList, { AddButton } from '../../components/StyledConfigList';
import { translate } from '../../shared/translate';
import FormRow from '../../components/FormRow';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import TextareaField from '../../components/FormPageMaker/Fields/TextareaField';
import SelectComboCountry from '../../components/SelectCombo/SelectComboCountry';
import MobileField from '../../components/FormPageMaker/Fields/MobileField';
import { checkPermissionV2 } from '../../components/PermissionMask';
import TrashButton from '../../components/TrashButton';
import SwitchField from '../../components/FormPageMaker/Fields/SwitchField';

export default ({ loading, values: { addresses = [], contactAddress = {} } = {}, setFieldValue, isSubmitting }) => {
  const disabled = isSubmitting || !checkPermissionV2('updateCustomer');
  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container spacing={3}>
          <FormRow title={translate.content_address} md={12}>
            <Card variant={'outlined'} square>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Grid container>
                      <FormRow title={translate.contact_person}>
                        <InputField
                          loading={loading}
                          disabled={disabled}
                          maxLength={50}
                          value={contactAddress.person}
                          onChange={(e) => {
                            contactAddress.person = e.target.value;
                            setFieldValue('contactAddress', contactAddress);
                          }}
                        />
                      </FormRow>
                      <FormRow title={translate.phone_no}>
                        <MobileField
                          loading={loading}
                          disabled={disabled}
                          value={contactAddress.tel}
                          onChange={(v) => {
                            contactAddress.tel = v;
                            setFieldValue('contactAddress', contactAddress);
                          }}
                        />
                      </FormRow>
                      <FormRow title={translate.email}>
                        <InputField
                          loading={loading}
                          disabled={disabled}
                          type={'email'}
                          value={contactAddress.email}
                          onChange={(e) => {
                            contactAddress.email = e.target.value;
                            setFieldValue('contactAddress', contactAddress);
                          }}
                        />
                      </FormRow>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <FormRow title={translate.address}>
                        <TextareaField
                          loading={loading}
                          disabled={disabled}
                          value={(contactAddress.lines || []).join('\n')}
                          onChange={(v) => {
                            contactAddress.lines = (v || '').split('\n');
                            setFieldValue('contactAddress', contactAddress);
                          }}
                        />
                      </FormRow>
                      <FormRow title={translate.country}>
                        <SelectComboCountry
                          loading={loading}
                          disabled={disabled}
                          value={contactAddress.country}
                          onChange={(country) => {
                            contactAddress.country = country;
                            setFieldValue('contactAddress', contactAddress);
                          }}
                        />
                      </FormRow>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </FormRow>
          <FormRow title={translate.mailing_address} xs={12}>
            <StyledConfigList>
              <Box m={1.5}>
                <Grid container spacing={3}>
                  {addresses.map((address, i) => {
                    const { id, person, tel, email, lines = [], country, isDefault } = address || {};
                    return (
                      <Grid key={id || i} item>
                        <Box mx={1.5}>
                          <Grid container>
                            <Grid item xs="true">
                              <FormRow title={translate.default}>
                                <SwitchField
                                  checked={isDefault || false}
                                  onChange={(e) => {
                                    address.isDefault = e.target.checked;
                                    addresses.forEach((address, j) => {
                                      if (i !== j) {
                                        address.isDefault = false;
                                      }
                                    });
                                    setFieldValue('addresses', addresses);
                                  }}
                                />
                              </FormRow>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                  <Grid container>
                                    <FormRow title={translate.contact_person} required={true}>
                                      <InputField
                                        disabled={disabled}
                                        maxLength={50}
                                        value={person}
                                        required={true}
                                        onChange={(e) => {
                                          address.person = e.target.value;
                                          setFieldValue('addresses', addresses);
                                        }}
                                      />
                                    </FormRow>
                                    <FormRow title={translate.phone_no} required={true}>
                                      <MobileField
                                        disabled={disabled}
                                        value={tel}
                                        required={true}
                                        onChange={(v) => {
                                          address.tel = v;
                                          setFieldValue('addresses', addresses);
                                        }}
                                      />
                                    </FormRow>
                                    <FormRow title={translate.email} required={true}>
                                      <InputField
                                        disabled={disabled}
                                        type={'email'}
                                        value={email}
                                        required={true}
                                        onChange={(e) => {
                                          address.email = e.target.value;
                                          setFieldValue('addresses', addresses);
                                        }}
                                      />
                                    </FormRow>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Grid container>
                                    <FormRow title={translate.address} required={true}>
                                      <TextareaField
                                        disabled={disabled}
                                        value={lines.join('\n')}
                                        required={true}
                                        onChange={(v) => {
                                          address.lines = (v || '').split('\n');
                                          setFieldValue('addresses', addresses);
                                        }}
                                      />
                                    </FormRow>
                                    <FormRow title={translate.country} required={true}>
                                      <SelectComboCountry
                                        disabled={disabled}
                                        value={country}
                                        required={true}
                                        onChange={(country) => {
                                          address.country = country;
                                          setFieldValue('addresses', addresses);
                                        }}
                                      />
                                    </FormRow>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Box mt={12}>
                                <TrashButton
                                  disabled={disabled}
                                  onClick={() => {
                                    addresses.splice(i, 1);
                                    setFieldValue('addresses', addresses);
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    <AddButton
                      disabled={loading || disabled}
                      onClick={() => {
                        if (!!addresses) {
                          addresses.push({});
                          setFieldValue('addresses', addresses);
                        } else {
                          setFieldValue('addresses', []);
                        }
                      }}
                      title={translate.add_mail_address}
                    />
                  </Grid>
                </Grid>
              </Box>
            </StyledConfigList>
          </FormRow>
        </Grid>
      </CardContent>
    </Card>
  );
};
