import { translate } from '../../../../shared/translate';
import BlobField from '../../../item_EmailTemplate/ComponentField/BlobField';
import React from 'react';
import InnerSelectField from '../../InnerSelectField';
import { Box, Grid, Typography } from '@material-ui/core';
import { Icon } from '../../../../components/IconRender';

export default {
  label: translate.text,
  value: 'isText',
  render: ({ suggestions, disabled, value, onChange = (_) => _ }) => (
    <Box px={1}>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item>
          <InnerSelectField
            placeholder={
              <>
                <Icon icon={'fa1'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />
                <Icon icon={'faText'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />
              </>
            }
            options={[
              {
                label: <AlignIconWithText iconOne="fa1" iconTwo="faText" />,
                value: 1,
                icon: <AlignIconWithText iconOne="fa1" iconTwo="faText" />,
              },
              {
                label: <AlignIconWithText iconOne="fa2" iconTwo="faText" />,
                value: 2,
                icon: <AlignIconWithText iconOne="fa2" iconTwo="faText" />,
              },
              {
                label: <AlignIconWithText iconOne="fa3" iconTwo="faText" />,
                value: 3,
                icon: <AlignIconWithText iconOne="fa3" iconTwo="faText" />,
              },
            ]}
            value={value?.size}
            onChange={(size) =>
              onChange({
                ...value,
                size,
              })
            }
          />
        </Grid>
        <Grid item>
          <InnerSelectField
            placeholder={<Icon icon={'faAlignLeft'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />}
            options={[
              {
                label: <AlignIconWithText iconOne="faAlignLeft" text={translate.alignLeft || '靠左'} />,
                value: 'left',
                icon: <Icon icon={'faAlignLeft'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />,
              },
              {
                label: <AlignIconWithText iconOne="faAlignCenter" text={translate.alignCenter || '置中'} />,
                value: 'center',
                icon: <Icon icon={'faAlignCenter'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />,
              },
              {
                label: <AlignIconWithText iconOne="faAlignRight" text={translate.alignRight || '靠右'} />,
                value: 'right',
                icon: <Icon icon={'faAlignRight'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />,
              },
            ]}
            value={value?.align}
            onChange={(align) =>
              onChange({
                ...value,
                align,
              })
            }
          />
        </Grid>
        <Grid item xs={true}>
          <BlobField
            suggestions={suggestions}
            disabled={disabled}
            value={value?.text}
            onChange={(text) => {
              onChange({
                ...value,
                text,
              });
            }}
          />
        </Grid>
      </Grid>
    </Box>
  ),
};

export const AlignIconWithText = ({ iconOne, iconTwo = undefined, text = undefined }) => {
  return (
    <Grid container spacing={1} justifyContent={!!iconTwo ? 'center' : undefined}>
      <Grid item>
        {!!iconTwo ? (
          <Box mb={0.5}>
            <Grid container>
              <Grid item>
                <Icon icon={iconOne} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />
              </Grid>

              <Grid item>
                <Icon icon={iconTwo} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Icon icon={iconOne} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />
        )}
      </Grid>
      {!!text && (
        <Grid item>
          <Typography>{text}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
