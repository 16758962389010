import React from 'react';
import { Grid, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import ChannelAvatar from './ChannelAvatar';
import { parseAgoTime, parseConnection } from '../../../../shared';
import {
  parseChannelCustomer,
  parseChannelPreviewContent,
} from '../../../../components/ChannelMessage/ChannelCustomerDetail';

const ChannelItem = ({ onClick, selected, channel }) => {
  const customer = parseChannelCustomer(channel) ?? {
    name: 'Guest',
    picture: '',
  };

  const {
      nodes: [{ read, createdAt } = {}],
    } = parseConnection(channel?.messages),
    display = parseChannelPreviewContent(channel);

  return (
    <ListItem selected={selected} onClick={onClick} style={{ cursor: 'pointer' }}>
      <ListItemAvatar>
        <ChannelAvatar read={read} customer={customer} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Grid container justify={'space-between'}>
            <Grid item xs={true}>
              <Typography
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                }}
              >
                {customer?.name}
              </Typography>
            </Grid>
            <Grid item>{/*<DesignatedPicker channel={channel} />*/}</Grid>
          </Grid>
        }
        secondary={
          <Grid container justify={'space-between'}>
            <Grid item xs={true}>
              <Typography className={'single-line-text'}>{display}</Typography>
            </Grid>
            <Grid item style={{ paddingTop: '6px' }}>
              <Typography variant={'caption'}>{parseAgoTime(createdAt)}</Typography>
            </Grid>
          </Grid>
        }
      />
    </ListItem>
  );
};

export default ChannelItem;
