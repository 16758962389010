import React from 'react';
import { CREATE_QUERY, GET_QUERY, UPDATE_QUERY } from '../query';
import FormPageMaker from '../../../components/FormPageMaker';
import { convertMetaArray, convertMetaObject, JSONParseSafely, parseConnection, PriceFormat } from '../../../shared';
import ConfigCard from '../ConfigCard';
import { LV, PHR } from '../../../components/ReceiptTemplatePreviewer';
import PreviewCard from '../PreviewCard';
import { client } from '../../../shared/apollo';
import SplitCard from '../../item_EmailTemplate/SplitCard';
import { defaultSuggestions } from '../defaultSuggestion';
import { translate } from '../../../shared/translate';

const { REACT_APP_ASSETS_URL } = process.env;

export default class LayoutFront extends FormPageMaker {
  type = 'FRONT';

  onCancelClick = null;
  async componentDidMount() {
    const [hk, cn, en] = await Promise.all([
      ...[
        `${REACT_APP_ASSETS_URL}/receipt_templates/default/zh-HK/${this.type}.json`,
        `${REACT_APP_ASSETS_URL}/receipt_templates/default/zh-CN/${this.type}.json`,
        `${REACT_APP_ASSETS_URL}/receipt_templates/default/en-US/${this.type}.json`,
      ].map(async (url) => {
        try {
          const res = await fetch(url);
          return await res.json();
        } catch (e) {}
      }),
    ]);

    this.setState({
      key: 1,
      defaultConfigs: {
        'zh-HK': hk,
        'zh-CN': cn,
        'en-US': en,
      },
    });
  }

  state = {
    ...this.state,
    defaultConfigs: {},
    id: localStorage.getItem('shopId'),
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
    },
    cards: [
      (props) => (
        <SplitCard
          configCard={
            <ConfigCard
              suggestions={[...defaultSuggestions]}
              allows={['isTitle', 'isText', 'isLV', 'isDivider', 'isLogo', 'isQRCode', 'isCut']}
              {...props}
            />
          }
          previewCard={
            <PreviewCard
              standardWidth={300}
              extraData={{}}
              extraRenderer={{
                isItems: ({ items }, config) => {
                  const size = config?.size;
                  return (
                    <>
                      <PHR />
                      <div
                        style={{ textAlign: 'center', fontSize: { 1: '1em', 2: '1.5rem', 3: '2rem' }[size] || '1em' }}
                      >
                        1. {translate.order_time || '下單時間'}: 18:13
                      </div>
                      {items?.map((item, i) => (
                        <div key={i}>
                          <LV
                            size={size}
                            label={
                              <>
                                {item?.quantity}x {item?.description}
                              </>
                            }
                            value={PriceFormat(item?.quantity * item?.unitPrice)}
                          />
                          <div
                            style={{
                              fontSize: { 1: '1em', 2: '1.5rem', 3: '2rem' }[size] || '1em',
                            }}
                          >
                            {item?.remark}
                          </div>
                        </div>
                      ))}
                    </>
                  );
                },
              }}
              {...props}
            />
          }
        />
      ),
    ],
  };

  submit = async (values) => {
    if (values.id) {
      await client.mutate({
        mutation: UPDATE_QUERY,
        variables: {
          id: values.id,
          input: {
            configs: JSON.stringify(values.configs),
            metadata: convertMetaArray(values?.metadata),
          },
        },
      });
    } else {
      await client.mutate({
        mutation: CREATE_QUERY,
        variables: {
          input: {
            configs: JSON.stringify(values.configs),
            metadata: convertMetaArray(values?.metadata),
            name: values.name || this.type,
            shopId: localStorage.getItem('shopId'),
            type: this.type,
          },
        },
      });
    }
    return false;
  };

  getExtraFetchVariables() {
    return {
      type: this.type,
    };
  }

  getInitialData(data) {
    const { receiptTemplates, ...shop } = data?.node ?? {};
    const { id, updatedAt, name, configs, metadata } = parseConnection(receiptTemplates).nodes?.[0] ?? {};
    return {
      id,
      updatedAt,
      name,
      shop,
      defaultConfigs: this.state.defaultConfigs?.[shop?.locale],
      configs: JSONParseSafely(configs, this.state.defaultConfigs?.[shop?.locale]),
      metadata: convertMetaObject(metadata),
    };
  }
}
