import React, { useEffect, useState } from 'react';
import { compareVersions } from 'compare-versions';
import { Dialog, DialogContentText, DialogContent, DialogActions, Grid, useTheme } from '@material-ui/core';
import { translate } from '../../shared/translate';
import SystemButton from '../SystemButton';
import CheckboxField from '../FormPageMaker/Fields/CheckboxField';
import useReady from '../../hooks/useReady';

const data = [
  {
    version: '51.0',
    content: {
      'zh-hk': `
<h5>系統更新公告</h5>
<p>後台版本：CMS 51</p>
<hr/>
<div style="margin-left: 16px; margin-top: 28px; margin-bottom: 35px;">
  <h6>本次更新針對產品分類頁面進行優化。更新內容如下：</h6>
  <div class="update-list-row">
    <div>✅ 新增  – </div>
    <div>產品分類 現支援開始及完結時間</div>
  </div>
</div>
<div style="margin-left: 16px;">
  <h6>本次更新包括以下系統改進項目：</h6>
  <div class="update-list-row">
    <div>✅ 優化  – </div>
    <div>優化頁面UI設計</div>
  </div>
  <div class="update-list-row">
    <div>✅ 修正  – </div>
    <div>解決會員頁面在特定情況下的顯示問題</div>
  </div>
  <div class="update-list-row">
    <div>✅ 修正  – </div>
    <div>解決產品及訂單頁面在特定情況下的顯示問題</div>
  </div>
  <div class="update-list-row">
    <div>✅ 修正  – </div>
    <div>解決轉倉單在特定情況下的異常問題</div>
  </div>
</div>
`,
      en: `
<h5>System Patch Notes</h5>
<p>Backend version: CMS 51</p>
<hr/>
<div style="margin-left: 16px; margin-top: 28px; margin-bottom: 35px;">
  <h6>This system update focuses on optimization of 'Product Category' interface. Updated contents are as follows:</h6>
  <div class="update-list-row">
    <div>✅ New Feature  – </div>
    <div>Product Category: "Online Date & Time" and "Offline Date & Time" can now be scheduled</div>
  </div>
</div>
<div style="margin-left: 16px;">
  <h6>System refinement and stability improvements:</h6>
  <div class="update-list-row">
    <div>✅ Optimized  – </div>
    <div>Optimized user interface and modules on system interface</div>
  </div>
  <div class="update-list-row">
    <div>✅ <span class="text-margin">Resolved</span>  – </div>
    <div>Addressed display issues that occurred on membership interface under certain circumstances</div>
  </div>
  <div class="update-list-row">
    <div>✅ <span class="text-margin">Resolved</span>  – </div>
    <div>Addressed display issues that occurred on product and order interface under certain circumstances</div>
  </div>
  <div class="update-list-row">
    <div>✅ <span class="text-margin">Resolved</span>  – </div>
    <div>Addressed issues that occurred on inventory transfer under certain circumstances</div>
  </div>
</div>
`,
    },
  },
];
{
  /* <div>✅ <span class="text-margin">Resolved</span>  – </div> */
}

const ReleaseNoteModal = () => {
  const theme = useTheme();
  const lang = localStorage.getItem('lang') || 'zh-hk';
  const [checked, setChecked] = useState(false);
  const [note, setNote] = useState(undefined);
  const ready = useReady(500);

  useEffect(() => {
    if (ready) {
      if (/^\/verify/.test(window.location.pathname)) return;
      if (/^\/print/.test(window.location.pathname)) return;
      if (/^\/login/.test(window.location.pathname)) return;
      if (/^\/test/.test(window.location.pathname)) return;
      if (/^\/initialize/.test(window.location.pathname)) return;

      const [readVersion, readLang] = (localStorage.getItem('readReleaseNoteCursor') || '0.0∆zh-hk').split('∆');
      const newNote = data.find((item) => compareVersions(item.version, readVersion) >= 0);

      if (newNote) {
        if (readVersion === newNote.version && readLang === lang) {
          setNote(undefined);
        } else {
          setNote(newNote);
        }
      }
    }
  }, [ready]);

  return (
    !!note && (
      <Dialog
        className={'release-note-modal'}
        // maxWidth={'sm'}
        fullWidth={true}
        open={true}
        onClose={() => {
          setNote(undefined);
        }}
        PaperProps={{
          style: {
            overflow: 'hidden',
            padding: theme.spacing(1),
            minHeight: '350px',
            maxHeight: '600px',
            maxWidth: '800px',
          },
        }}
      >
        <DialogContent style={{ padding: `${theme.spacing(3)}px ${theme.spacing(4)}px` }}>
          <DialogContentText>
            <style>{`
.release-note-modal h5 {
  font-size: 18px;
  font-weight: 600;
}
.release-note-modal h6 {
  color: ${theme.palette.secondary.main};
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
}
.release-note-modal h6:before{
  content: "‧";
  margin-left: -16px;
  margin-right: 8px;
}
.release-note-modal p {
  margin-bottom: 8px;
  font-size: 15px;
}
.update-list-row {
  display: flex;
  flex-direction: row;
  margin: 8px 0;
}
.update-list-row div:first-child {
  margin-right: 5px;
  min-width: fit-content;
}
.release-note-modal ul {
  padding-left: 16px;
}

.text-margin {
  margin-left: 4.5px;
  margin-right: 3.5px
}
          `}</style>
            <div dangerouslySetInnerHTML={{ __html: note?.content?.[lang] }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={'flex-end'} alignItems={'center'} spacing={3}>
            <Grid item>
              <CheckboxField
                textVariant={'p'}
                checked={checked}
                onChange={() => setChecked((v) => !v)}
                text={translate.dont_show_again}
              />
            </Grid>
            <Grid item>
              <SystemButton
                color={'omniewRed'}
                onClick={() => {
                  setNote(undefined);
                  if (checked) {
                    localStorage.setItem('readReleaseNoteCursor', `${note.version}∆${lang}`);
                  }
                }}
              >
                {translate.confirm}
              </SystemButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  );
};

export default ReleaseNoteModal;
