import React from 'react';
import Dnd from '../../../components/DND';
import { Box, Card, Grid } from '@material-ui/core';
import { Icon } from '../../../components/IconRender';
import ComponentItemField from './ComponentItemField';
import { AddButton } from '../../../components/StyledConfigList';
import { ReactComponent as IconDragVertical } from '../../../assets/icon/drag-vertical.svg';
import TrashButton from '../../../components/TrashButton';
import uuid from 'react-uuid';

const ComponentField = ({
  options,
  suggestions = [],
  allows,
  disableOptions,
  extraOptions = [],
  value: items = [],
  onChange = (_) => _,
  disabled,
}) => {
  return (
    <Card variant={'outlined'}>
      <Box p={2} bgcolor={'#eee'}>
        <Dnd
          items={items}
          onChange={onChange}
          disabled={disabled}
          spacing={2}
          direction={'column'}
          getKey={(item, i) => item?.id || i}
          renderItem={(item, i, { nonDraggableProps }) => {
            const type = Object.keys(item).find((key) => /^is[A-Z]/.test(key));
            const canDelete = !type || allows?.includes(type);

            return (
              <Grid container spacing={1}>
                <Grid item style={{ cursor: 'pointer', paddingTop: 11 }}>
                  <Icon icon={IconDragVertical} type={'svg'} />
                </Grid>
                <Grid item xs={true} {...nonDraggableProps}>
                  <ComponentItemField
                    options={options}
                    suggestions={suggestions}
                    value={item}
                    allows={allows}
                    disableOptions={disableOptions}
                    extraOptions={extraOptions}
                    disabled={disabled}
                    onChange={(item) => {
                      onChange(items.map((it, j) => (i === j ? item : it)));
                    }}
                  />
                </Grid>
                <Grid item style={{ alignSelf: 'center' }}>
                  <TrashButton
                    size="small"
                    disabled={disabled || !canDelete}
                    onClick={() => {
                      onChange(items.filter((_, j) => i !== j));
                    }}
                  />
                </Grid>
              </Grid>
            );
          }}
        >
          <AddButton
            onClick={() => {
              if (allows?.length === 1) onChange(items.concat({ [allows[0]]: true }));
              else onChange(items.concat({ id: uuid() }));
            }}
          />
        </Dnd>
      </Box>
    </Card>
  );
};

export default ComponentField;
