import { getCurrentRoles } from '../shared';

const PermissionMap = {
  manageShops: ['Admin'],
  viewDevices: ['Admin'],

  viewCustomers: ['Admin', 'Owner', 'ChicksStaff', 'ChicksStaffManager', 'ChicksStaffOrder', 'ChicksCustomer'],
  createCustomer: ['Admin', 'Owner', 'ChicksStaffOrder', 'ChicksCustomer'],
  updateCustomer: ['Admin', 'Owner', 'ChicksStaffOrder', 'ChicksCustomer'],
  updateCustomerRemark: ['Admin', 'Owner', 'ChicksStaffOrder', 'ChicksStaffManager', 'ChicksCustomer'],
  useCustomerCoupons: ['Admin', 'Owner', 'ChicksStaffOrder', 'ChicksStaffManager', 'ChicksCustomer'],

  viewCustomerFields: ['Admin', 'Owner'],

  viewReceivePurchases: ['Admin', 'Owner', 'Cashier', 'Warehouse', 'ProductAndWarehouse'],
  createReceivePurchase: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],
  updateReceivePurchase: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],
  rejectReceivePurchase: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],
  completeReceivePurchase: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],

  viewStockTakes: ['Admin', 'Owner', 'Cashier', 'Warehouse', 'ProductAndWarehouse'],
  exportStockTake: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],
  importStockTake: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],
  rejectStockTake: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],
  completeStockTake: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],

  viewStockOut: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],
  completeStockOut: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],
  cancelStockOut: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],
  voidStockOut: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],

  viewTransfers: ['Admin', 'Owner', 'Cashier', 'Warehouse', 'ProductAndWarehouse'],
  createTransfer: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],
  updateTransfer: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],
  approveTransfer: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],
  rejectTransfer: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],
  completeTransfer: ['Admin', 'Owner', 'Warehouse', 'ProductAndWarehouse'],

  viewOrders: ['Admin', 'Owner', 'ChicksStaffOrder', 'Cashier'],
  createOrder: ['Admin', 'Owner', 'ChicksStaffOrder'],
  updateOrder: ['Admin', 'Owner', 'ChicksStaffOrder'],
  exportOrder: ['Admin', 'Owner', 'ChicksStaffOrder'],
  confirmOrder: ['Admin', 'Owner', 'ChicksStaffOrder'],
  cancelOrder: ['Admin', 'Owner', 'ChicksStaffOrder'],
  getPackingList: ['Admin', 'Owner', 'ChicksStaffOrder'],
  getInvoicePrints: ['Admin', 'Owner', 'ChicksStaffOrder'],

  viewStaffSettings: ['Admin', 'Owner'],
  updateStaff: ['Admin', 'Owner'],
  createStaff: ['Admin', 'Owner'],

  viewReturns: ['Admin', 'Owner'],
  exportReturn: ['Admin', 'Owner'],

  createWarehouse: ['Admin'],

  readNotification: ['Admin', 'Owner'],
  enablePaymentMethod: ['Admin'],
  viewDashboard: ['Admin', 'Owner'],

  viewDeliveryNotes: ['Admin', 'Owner', 'ChicksStaffOrder'],
  viewCustomerChats: ['Admin', 'Owner', 'ChicksStaffOrder'],
  viewSystemMessages: ['Admin', 'Owner', 'ChicksStaffOrder'],
  viewProducts: ['Admin', 'Owner', 'ProductAndWarehouse'],
  viewBundleProducts: ['Admin', 'Owner', 'ProductAndWarehouse'],
  viewCollections: ['Admin', 'Owner', 'ProductAndWarehouse'],
  viewAddOnProducts: ['Admin', 'Owner', 'ProductAndWarehouse'],
  viewProductModifiers: ['Admin', 'Owner', 'ProductAndWarehouse'],
  viewCashVouchers: ['Admin', 'Owner'],
  viewStockOverview: ['Admin', 'Owner', 'Cashier', 'ProductAndWarehouse'],
  viewWarehouses: ['Admin', 'Owner', 'ProductAndWarehouse'],
  viewCoupons: ['Admin', 'Owner'],
  viewDiscounts: ['Admin', 'Owner'],
  viewAnalystPlatforms: ['Admin', 'Owner'],
  viewCampaigns: ['Admin', 'Owner'],
  viewSubscriptions: ['Admin', 'Owner'],
  viewSalesReports: ['Admin', 'Owner', 'Accountant'],
  viewCustomerReports: ['Admin', 'Owner', 'Accountant'],
  viewCustomPages: ['Admin', 'Owner'],
  viewMarquees: ['Admin', 'Owner'],
  viewArticles: ['Admin', 'Owner'],
  viewForms: ['Admin', 'Owner'],
  viewPopups: ['Admin', 'Owner'],
  viewNavigationTemplates: ['Admin', 'Owner'],
  viewWebsiteSettings: ['Admin', 'Owner'],
  viewMerchantSettings: ['Admin', 'Owner'],
  viewDeliveryOptions: ['Admin', 'Owner'],
  viewCredentials: ['Admin'],
  viewPaymentMethods: ['Admin', 'Owner'],
  viewEmailTemplates: ['Admin', 'Owner'],
  viewReceiptTemplates: ['Admin', 'Owner'],
  viewCashFlowStatements: ['Admin', 'Owner'],
  viewMemberTiers: ['Admin', 'Owner'],
  viewUsers: ['Admin'],
  viewAccountSetting: ['Admin', 'Owner'],
  viewProductOrderSetting: ['Admin', 'Owner'],
  viewPrintoutSetting: ['Admin', 'Owner'],
  viewAttributes: ['Admin', 'Owner'],
  viewVenues: ['Admin', 'Owner'],
  viewTables: ['Admin', 'Owner'],
  viewServices: ['Admin', 'Owner'],

  viewServiceLocations: ['Admin', 'Owner'],
  viewAppointments: ['Admin', 'Owner'],
  viewMerchantRemark: ['Admin'],

  autoGeneration: ['Admin'],
  viewGateSetting: ['Admin', 'Owner'],
  viewAllPosDevices: ['Admin'],

  posDailyReport: ['Admin', 'Owner'],

  chatGPT: ['Admin'],
  photoroom: ['Admin'],
};

export function checkPermissionV2(permission = '') {
  const roles = getCurrentRoles();
  if (roles.includes('*')) return true;
  return roles.reduce((reducer, role) => {
    if (reducer) return reducer;
    return (PermissionMap[permission] || []).includes(role);
  }, false);
}
