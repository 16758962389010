import { gql } from '@apollo/client';

const FRAGMENT_RECEIPT_TEMPLATE = gql`
  fragment FRAGMENT_RECEIPT_TEMPLATE on ShopReceiptTemplate {
    id
    updatedAt
    createdAt
    active
    name
    type
    configs
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $type: String) {
    node(id: $id) {
      id
      ... on CompanyShop {
        name
        locale
        address {
          city
          country
          email
          lines
          person
          tel
        }
        logoMedia {
          src
        }
        rasterLogoMedia {
          src
        }
        customDomain
        hostname
        metadata {
          key
          value
        }
        receiptTemplates(limits: 1, filter: { type: { operator: IN, value: [$type] } }) {
          nodes {
            ...FRAGMENT_RECEIPT_TEMPLATE
          }
        }
      }
    }
  }
  ${FRAGMENT_RECEIPT_TEMPLATE}
`;

export const CREATE_QUERY = gql`
  mutation ($input: ShopReceiptTemplateCreateInput!) {
    receiptTemplateCreate(input: $input) {
      ...FRAGMENT_RECEIPT_TEMPLATE
    }
  }
  ${FRAGMENT_RECEIPT_TEMPLATE}
`;
export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: ShopReceiptTemplateUpdateInput!) {
    receiptTemplateUpdate(id: $id, input: $input) {
      ...FRAGMENT_RECEIPT_TEMPLATE
    }
  }
  ${FRAGMENT_RECEIPT_TEMPLATE}
`;
