import React from 'react';
import FormCard from '../../components/FormPageMaker/Cards/FormCard';
import { translate } from '../../shared/translate';
import ComponentField from '../item_EmailTemplate/ComponentField';
import LSkeleton from '../../components/LSkeleton';
import SystemButton from '../../components/SystemButton';
import module from './ComponentField/module';
import { Grid } from '@material-ui/core';
import RawDataModalButton from './PageDataModalButton';
import uuid from 'react-uuid';

const ConfigCard = ({ suggestions, footerSuggestions, allows, disableOptions, ...actions }) => {
  const { setFieldValue, values } = actions;
  return (
    <>
      <FormCard
        actions={actions}
        fields={[
          {
            label: ({ disabled }) => (
              <Grid container spacing={2}>
                <Grid item>
                  <SystemButton
                    color={'secondary'}
                    disabled={disabled}
                    onClick={() => {
                      setFieldValue('configs', { ...values.defaultConfigs });
                    }}
                  >
                    {translate.use_default}
                  </SystemButton>
                </Grid>
                <Grid item>
                  <RawDataModalButton
                    disabled={disabled}
                    value={values.configs}
                    onChange={(v) => setFieldValue('configs', v)}
                  />
                </Grid>
              </Grid>
            ),
          },
          {
            render: ({ values, setFieldValue, disabled, loading }) => {
              const bodyItems = values.configs?.bodyItems.map((item) => ({ ...item, id: item?.id ? item.id : uuid() }));

              if (loading)
                return (
                  <>
                    <LSkeleton height={30} />
                    <LSkeleton height={30} />
                    <LSkeleton height={30} />
                    <LSkeleton height={30} />
                  </>
                );
              return (
                <ComponentField
                  options={module}
                  suggestions={suggestions}
                  allows={allows}
                  disableOptions={disableOptions}
                  disabled={disabled}
                  value={bodyItems}
                  onChange={(items) =>
                    setFieldValue('configs', {
                      ...values.configs,
                      bodyItems: items,
                    })
                  }
                />
              );
            },
          },
        ]}
      />
    </>
  );
};

export default ConfigCard;
