import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';
import SelectCombo from './index';
import { translate as t } from '../../shared/translate';

export const GET_COUNTRIES = gql`
  query {
    __type(name: "Country") {
      name
      enumValues {
        name
      }
    }
  }
`;

const SelectComboCountry = ({ limitedCountries, filteredCountries = [], ...props }) => {
  const [open, setOpen] = useState(false);

  const { loading, data } = useQuery(GET_COUNTRIES, {
    skip: !open,
  });

  const { __type } = data || {},
    { enumValues } = __type || {};
  const options = (enumValues || [])
    .map(({ name }) => {
      if (!!limitedCountries && !~limitedCountries.indexOf(name)) return null;
      if (~filteredCountries.indexOf(name)) return null;
      return name;
    })
    .filter((_) => _);

  return (
    <SelectCombo
      onOpen={setOpen}
      getOptionSelected={(a, b) => a === b}
      getOptionLabel={(option) => `${option} ${t.countries[option]}`}
      options={_.sortBy(options, (value) => ['SGP', 'TWN', 'CHN', 'MAC', 'HKG'].indexOf(value) * -1)}
      loading={loading}
      {...props}
    />
  );
};

export default SelectComboCountry;
