import FormPageMaker from '../../components/FormPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY, POST_QUERY, REMOVE_QUERY } from './query';
import WeightCard from './WeightCard';
import { client } from 'shared/apollo';
import AddressesCard from './AddressesCard';
import { DeliveryMethodProvider, SFDeliveryMethodProvider } from '../../shared/omniwe-types';
import { cloneObject, convertMetaObject, getCredential } from '../../shared';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import ActivityIndicator from '../../components/ActivityIndicator';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.card_title_delivery_option_details,
        cards: [
          {
            fields: [
              {
                label: translate.delivery_method_name,
                type: 'text',
                name: 'name',
                required: true,
                md: 6,
              },
              {
                label: translate.sort_index,
                description:
                  translate.display_order_description ||
                  '「顯示次序」指的是本項目在前端網站或POS上呈現的次序，系統以升序排列，數字越小，顯示越靠前。',
                type: 'number',
                name: 'sortIndex',
                required: true,
                md: 6,
              },
            ],
          },
          WeightCard,
          {
            fields: [
              {
                label: translate.delivery_country,
                type: 'countries',
                name: 'countries',
                required: true,
              },
              {
                label: translate.delivery_method,
                type: 'select',
                name: 'provider',
                options: Object.keys(DeliveryMethodProvider).map((value) => ({
                  label: DeliveryMethodProvider[value].label,
                  value,
                })),
                required: true,
                afterChanged: async (v, { setFieldValue }) => {
                  try {
                    ActivityIndicator.show(translate.check_credential);
                    switch (v) {
                      case 'SF_EXPRESS':
                        if (!(await getCredential('SF_EXPRESS'))) throw new Error('No related credential');
                        break;
                      default:
                        break;
                    }
                  } catch (e) {
                    toast.error(errorParser(e));
                    setFieldValue('provider', null);
                  } finally {
                    ActivityIndicator.hide();
                  }
                },
              },
              {
                label: translate.pickup_option || '取貨方式',
                type: 'select',
                name: 'shipAny',
                required: true,
                display: ({ values }) => values.provider === 'SHIPANY_SF_PICKUP',
                options: Object.keys(SFDeliveryMethodProvider).map((value) => {
                  return {
                    label: SFDeliveryMethodProvider[value].label,
                    value: SFDeliveryMethodProvider[value].value,
                  };
                }),
                afterChanged: async (v, { setFieldValue }) => {
                  try {
                    setFieldValue('shipAny', v);
                  } catch (e) {
                    toast.error(errorParser(e));
                    setFieldValue('shipAny', null);
                  } finally {
                    ActivityIndicator.hide();
                  }
                },
              },
            ],
          },
          AddressesCard,
          {
            fields: [
              {
                label: translate.description,
                description: translate.please_input_description || '請輸入簡介',
                type: 'textarea',
                name: 'description',
                maxLength: 160,
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          shopId: localStorage.getItem('shopId'),
          name: values.name,
          sortIndex: values.sortIndex,
          provider: values.provider,
          active: values.active,
          countries: values.countries,
          metadata: !!values?.shipAny
            ? [
                { key: 'description', value: values?.description },
                { key: 'shipAny', value: values?.shipAny },
              ]
            : [{ key: 'description', value: values?.description }],
          expressions: {
            FREE: [{ maxWeight: 99999, unit: 0, unitPrice: 0 }],
            FIXED: [{ maxWeight: 99999, unit: 0, unitPrice: values.expressions[0].unitPrice }],
            CUSTOM: values.expressions.map((expression) => ({
              maxWeight: expression.maxWeight - 0,
              unit: expression.unit - 0 || 0,
              unitPrice: expression.unitPrice - 0 || 0,
              __typename: undefined,
            })),
          }[values.mode],
          // addresses: (values.addresses || []).map((address) => ({
          //   id: address.id,
          //   name: address.name || '',
          //   person: '',
          //   tel: address.tel || '',
          //   email: address.email || '',
          //   lines: address.lines.filter((_) => _),
          //   city: address.city || '',
          //   country: address.country || values.countries[0] || 'HKG',
          // })),
        },
      },
    });
    return true;
  };
  getInitialData({ node } = {}) {
    const {
      active = true,
      name,
      provider,
      countries = [],
      sortIndex = 0,
      expressions: _expressions,
      updatedAt,
      addresses,
      metadata: _metadata = [],
    } = node || {};

    const expressions = !_expressions || _expressions.length === 0 ? [{ unit: 0, maxWeight: 9999 }] : _expressions;
    const metadata = convertMetaObject(_metadata);

    return {
      active,
      name,
      provider,
      shipAny: metadata?.shipAny || null,
      countries,
      sortIndex,
      updatedAt,
      mode:
        expressions.length === 1 && (expressions[0] || {}).unit === 0
          ? (expressions[0] || {}).unitPrice === 0
            ? 'FREE'
            : 'FIXED'
          : 'CUSTOM',
      expressions: cloneObject(expressions),
      addresses: cloneObject(addresses || []),
      description: metadata?.description || '',
    };
  }
}
