import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { translate } from '../../shared/translate';
import SwitchField from '../../components/FormPageMaker/Fields/SwitchField';

const PushSettingCard = ({ value: metadata, onChange, loading, disabled }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs md={4}>
            <Typography>SMS</Typography>
          </Grid>
          <Grid item xs="auto" md={2}>
            <SwitchField
              loading={loading}
              disabled={disabled}
              checked={!metadata.notReceiveSMS}
              onChange={() => onChange({ ...metadata, notReceiveSMS: !metadata.notReceiveSMS ? 1 : 0 })}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs md={4}>
            <Typography>WhatsApp</Typography>
          </Grid>
          <Grid item xs="auto" md={2}>
            <SwitchField
              loading={loading}
              disabled={disabled}
              checked={!metadata.notReceiveWhatsApp}
              onChange={() => onChange({ ...metadata, notReceiveWhatsApp: !metadata.notReceiveWhatsApp ? 1 : 0 })}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs md={4}>
            <Typography>Email</Typography>
          </Grid>
          <Grid item xs="auto" md={2}>
            <SwitchField
              loading={loading}
              disabled={disabled}
              checked={!metadata.notReceiveEmail}
              onChange={() => onChange({ ...metadata, notReceiveEmail: !metadata.notReceiveEmail ? 1 : 0 })}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs md={4}>
            <Typography>{translate.mail || '郵寄'}</Typography>
          </Grid>
          <Grid item xs="auto" md={2}>
            <SwitchField
              loading={loading}
              disabled={disabled}
              checked={!metadata.notReceiveMail}
              onChange={() => onChange({ ...metadata, notReceiveMail: !metadata.notReceiveMail ? 1 : 0 })}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs md={4}>
            <Typography>{translate.app_notification || 'App推送'}</Typography>
          </Grid>
          <Grid item xs="auto" md={2}>
            <SwitchField
              loading={loading}
              disabled={disabled}
              checked={!metadata.notReceivePushNotification}
              onChange={() =>
                onChange({ ...metadata, notReceivePushNotification: !metadata.notReceivePushNotification ? 1 : 0 })
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PushSettingCard;
