import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import LeftSideBar from './leftSideBar';
import AgentMenu from './_agentMenu';
import getOwnerMenu from './_ownerMenu';
import CommonPage from './_commonPages';
import Wrapper from './Wrapper';
import { GET_QUERY } from './query';
import LoginChecker from './LoginChecker';
import { flattenItems, parseMedia } from '../../shared';
import { useQuery } from '@apollo/client';
import HashWatcher from '../../components/HashWatcher';
import { checkPermissionV2 } from '../../components/PermissionMask';

export default (props) => {
  const { history } = props;
  const shopId = localStorage.getItem('shopId');
  const { loading, data } = useQuery(GET_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { shopId },
    skip: !shopId,
  });
  const { shop, me } = data || {};
  const { name, logoMedia } = shop || {},
    logo = parseMedia(logoMedia);
  const { email, picture } = me || {};
  const menu = parsePermittedMenus(!!shopId ? getOwnerMenu(shop?.type) : AgentMenu);
  const pages = CommonPage.concat(flattenItems(menu, 'children'));

  // check does localStorage have shopId if not push to /shops
  useEffect(() => {
    if (!shopId) {
      history.push('/shops');
    }
  }, []);

  return (
    <>
      <HashWatcher
        onHashChange={(hash) => {
          setTimeout(() => {
            const el = document.getElementById(hash);
            if (!!el) {
              el.className = el.className + ' on-hash';
            }
          }, 1000);
        }}
      />
      <Box display={'flex'} height={'100%'}>
        <LeftSideBar
          {...props}
          identity={email}
          logo={logo}
          title={name}
          menu={menu}
          picture={picture}
          onMenuClick={({ link }) => history.push(link)}
          loading={loading}
        />
        <Wrapper {...props} loading={loading} pages={pages} shop={shop || {}} me={me || {}} />
        <LoginChecker />
      </Box>
    </>
  );
};

function parsePermittedMenus(menus) {
  if (!menus) return undefined;
  return menus
    .map((stack) => {
      if (!stack) return undefined;
      stack.children = parsePermittedMenus(stack.children);
      if (stack.permission) {
        return checkPermissionV2(stack.permission) ? stack : undefined;
      } else return stack;
    })
    .filter((_) => _);
}
